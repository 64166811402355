import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FurtherReading from "../components/furtherReading"

const Tyler = () => (
  <Layout>
    <SEO
      title="Tyler Talks Natural Hair Care"
      description="Tyler the natural hair enthusiast from Grenada talks about her routine and her favourite products. She has some amazing hairstyles for black women"
    />
    <h1>Tyler Talks Natural Hair Care</h1>
    <h2 style={{color:"Orchid"}}>Island girl and Natural Hair enthusiast Tyler tells us what it takes to get beautiful natural afro hair</h2>
    <p>This week we had a chat with the beautiful Tyler, she's only 18 and already so focused. We can't wait to see what she gets up to. Make sure you follow her on Instagram <a
        href="https://www.instagram.com/knotsncurls/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      @knotsncurls
      </a> and check out her youtube channel <a
        href="https://www.youtube.com/knotsncurls"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      knotsncurls
      </a>.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/4nRwMry.jpg"
        alt="Tyler Grenada natural hair knotsncurls"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Tyler</p>
    </div>

    <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}><i>As an Amazon Associate I earn from qualifying purchases. Some of the links in this article may be affiliate links, which means I may receive a small commission at no additional cost to you if you decide to purchase something.</i></p>

    <h3>Hello Tyler, tell us a bit about yourself</h3>
    <p>My name is Tyler and I am an 18 year old island girl. In West Indies, Grenada born and raised, on the beaches is where I spent most of my days. I am also a student & natural hair enthusiast.</p>

    <h3>What does your natural hair mean to you?</h3>
    <p>To me, natural hair is freedom, acceptance, confidence and patience.</p>

    <h3>How would you describe your hair and what is your family history?</h3>
    <p>My head is filled with various types of hair textures. The front & nape area is where my curls are looser & softer. The middle is slightly coarser & the curls are much tighter. My family, as far as I know is a long line of beautiful black Caribbean people with no mixed ethnicities.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/ZVFcUJd.jpg"
        alt="Tyler knotsncurls different hair textures"
      />
    </div>

    <h3>Talk us through your hair routine</h3>
    <p>My hair routine changes from time to time but currently, I wash my hair with shampoo & conditioner every week & deep condition, which I think is key. I try to style my hair once for the week but sometimes I change styles within the week.</p>

    <p>My favorite hair products are:</p>
    <p><a
        href="https://www.amazon.com/Honey-Miracle-Conditioner-Natural-Olive/dp/B00FN3EIS4/ref=as_li_ss_tl?dchild=1&keywords=TGIN+Honey+Miracle+Hair+Mask&qid=1598558788&sr=8-5&linkCode=ll1&tag=natural032d-20&linkId=01e9e85791e1b7b55f10e08b02a7b9cc&language=en_US"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style={{ textDecoration: "none" }}
      >
      TGIN Honey Miracle Hair Mask
      </a></p>
    <p><a
        href="https://floracurl.co.uk/products/rose-honey-leave-in-detangler-conditioner"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      Flora & Curl Organic Rose & Honey Milk Leave-in Detangler
      </a></p>

      <h3>How did you view your hair growing up? </h3>
      <p>Honestly, I didn’t think much about my hair when I was younger, too busy playing, lol. However, I can say that I did not like having to comb my hair. The process would be so long & painful. I would always receive compliments on my hair.</p>

      <h3>Did you have role model growing up that helped you & where did you learn to take care of your hair?</h3>
      <p>I first relaxed my hair when I was 11; I was entering high school & wanted to be able to do my hair myself. My relaxed hair was very healthy & thick. Around the age of 13, I started literally binge watching YouTube videos & always stumbled upon videos about natural hair.</p>
      <p>I loved watching <a
        href="https://www.youtube.com/MahoganyCurls"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      Mahoganycurls
      </a> & <a
        href="https://www.youtube.com/Naptural85"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      Naptural85
      </a>. The way they took care of their hair & how beautiful it looked inspired me to return to natural & I took that journey by transitioning.</p>

      <h3>What happened when you started doing your hair yourself?</h3>
      <p>At first, doing my hair, especially while transitioning was a difficult task, I now had two completely different textures to take care of. The availability of great natural hair products was none existent & so it was a struggle but I managed.</p>

      <h3>Any funny stories?</h3>
      <p>One day, after I had fully transitioned, I was attempting a flexi rod set but no matter what I did, it was not coming out the way I wanted it to & the flexi rods were not staying in. I got so frustrated & irritated that I just cut off about half of my hair. My mom freaked but then she got over it. That same day I got it professionally cut really short, my hair was about 2-3 inches.</p>

      <h3>Is there anything you wish you knew sooner?</h3>
      <p>I believe that I had to go through the entire process of figuring it all out to be able to know exactly how to take care of my hair now. I have no regrets.</p>

      <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/Cu2drW1.jpg"
        alt="Tyler knotsncurls posing with natural afro hair"
      />
    </div>

    <h3>What advice would you give for taking care of natural hair?</h3>
    <p>Find a routine that works for you & stick to it.</p>
    <p>Do what is necessary for your hair, if you have damaged ends or split ends, cut it off! Don’t hold onto it because of length it can adversely affect your hair in the long run.</p>

    <h3>What is your favourite hairstyle right now?</h3>
    <p>I love doing High Puffs because it is the quickest & easiest.</p>

    <h3>Do you have any hair plans for the future?</h3>
    <p>This year I wanted to take many risks & have fun with my natural hair so I do plan on dying it, something very different & bold.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/LUGeWaT.jpg"
        alt="Tyler knotsncurls in the sun"
      />
    </div>

    <h3>What are you passionate about?</h3>
    <p>I’m passionate about helping others realize their full potential & worth and also I’m passionate about attaining full happiness.</p>

    <h3>How can we follow/ contact you?</h3>
    <p>INSTAGRAM: <a
        href="https://www.instagram.com/knotsncurls/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      @knotsncurls
      </a> </p>
    <p>YOUTUBE: <a
        href="https://www.youtube.com/knotsncurls"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      https://www.youtube.com/knotsncurls
      </a></p>



    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}




    <FurtherReading
      content={
        <div>
        <p>
            <Link to="/talking-with-leah-alexxanderr-caine/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             Natural Hair with Model Leah Alexxanderr-Caine
            </Link>{" "}
            - We had a chat with the amazing Leah Alexxanderr-Caine. She is a model born & raised in east London, currently living in Essex...
          </p>
          <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>          
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default Tyler
